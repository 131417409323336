import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticlesList from '../components/ArticlesList'
import TabDescription from '../components/TabDescription'
import SEO from '../components/SEO'
import Video from '../components/Video'

export const query = graphql`
  {
    mytab: contentfulTabDescription(tabname: { eq: "About" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }

    article: allContentfulArticle(
      sort: { fields: order, order: DESC }
      filter: { featured: { eq: true } }
    ) {
      nodes {
        featured
        createdWhen
        createdBy
        id
        order
        tags
        title
        image {
          gatsbyImageData
          title
        }
      }
    }
  }
`
const About = ({
  data: {
    mytab,
    article: { nodes: articles },
  },
}) => {
  return (
    <Layout>
      <SEO
        title='Over mij'
        description='Hi, ik ben Sandra. Mama van 3 kids. Personal Trainer te Blanden. Ik help jou graag op weg naar een sterkere versie van jezelf.'
        image='/images/about_by_laurieusfit.jpg'
      />
      <main className='page'>
        <section className='about-page'>
          <article>
            <TabDescription {...mytab} />
            <form
              action='https://formspree.io/f/xayvpdrz'
              method='POST'
              className='form contact-form'
            >
              <div className='form-row'>
                <h1>Zeg Hallo!</h1>
                <label htmlFor='name'>Je naam</label>
                <input type='text' name='name' id='name' required />
              </div>
              <div className='form-row'>
                <label htmlFor='email'>Je email of telefoon</label>
                <input type='text' name='email' id='email' required />
              </div>
              <div className='form-row'>
                <label htmlFor='question'>Jouw berichtje</label>
                <textarea name='question' id='question' />
              </div>
              <div className='form-row'>
                <button type='submit' className='btn hipster block'>
                  Verzenden
                </button>
              </div>
            </form>
          </article>
          <article>
            <div className='services-img-container'>
              <StaticImage
                src='../images/about/ipitup_by_ptsandra.jpg'
                alt='Ip It Up Bank'
                className='about-img'
                placeholder='tracedSVG'
              />
              <StaticImage
                src='../images/about/torenvalk_by_ptsandra.jpg'
                alt='Strong By Sandra'
                className='about-img'
                placeholder='tracedSVG'
              />
              <StaticImage
                src='../images/about/torenvalk2_by_ptsandra.jpg'
                alt='Strong By Sandra'
                className='about-img'
                placeholder='tracedSVG'
              />
              <StaticImage
                src='../images/about/torenvalk3_by_ptsandra.jpg'
                alt='Strong By Sandra'
                className='about-img'
                placeholder='tracedSVG'
              />
              {/* <h2>Sfeerbeeld</h2>
              <Video
                videoSrcURL='https://youtube.com/embed/Br9bEnL5dbU'
                videoTitle='Sfeerbeeld'
              /> */}
            </div>
          </article>
        </section>
        <section className='featured-recipes'>
          <h4>Kijk ook eens hier!</h4>
          <ArticlesList articles={articles}></ArticlesList>
        </section>
      </main>
    </Layout>
  )
}

export default About
